import { AuthLayout, ErrorPage } from '@app/components'
import { type NextPage } from 'next'
import { useRouter } from 'next/router'

const NotFoundPage: NextPage = () => {
  const router = useRouter()

  return (
    <AuthLayout title='Not Found'>
      <ErrorPage
        buttonText='Take me back to home page'
        description='Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.'
        onButtonClick={() => router.push('/')}
        statusCode={404}
        title='Not Found'
      />
    </AuthLayout>
  )
}

// noinspection JSUnusedGlobalSymbols
export default NotFoundPage
